@import "../styles/variables";

.app-input {
  box-shadow: $input-box-shadow;
  border-radius: $input-border-radius;
  height: 57px;
  color: var(--app-primaryText);
}

.app-input-number {
  border-radius: $input-border-radius;
  height: 33px;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  width: 125px;
}

.ant-select.app-select {
  font-weight: 300;

  .ant-select-arrow svg {
    width: 14px;
    height: 9px;
    path {
      fill: var(--app-selectOptionBorder);
    }
  }

  &.ant-select-focused:where(
      .css-dev-only-do-not-override-1jjzx9h
    ).ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: var(--app-selectBorder);
    outline: 0;
  }

  .ant-select-selector {
    padding: 7px 15px;

    .ant-select-selection-item {
      padding: 7px 15px;
    }
  }

  &.ant-select-open {
    .ant-select-focused:where(
        .css-dev-only-do-not-override-1jjzx9h
      ).ant-select-outlined
      :not(.ant-select-disabled)
      :not(.ant-select-customize-input)
      :not(.ant-pagination-size-changer)
      .ant-select-selector {
      border-color: var(--app-selectBorder);
      box-shadow: none;
    }

    .ant-select-selector {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 85%;
        border-top: 0.2px solid var(--app-selectOptionBorder);
        visibility: visible;
      }
    }

    .ant-select-arrow svg {
      transform: rotate(180deg);
    }
  }

  .ant-select-dropdown {
    border: 1px solid var(--app-selectBorder);
    border-top: 0;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px 0 24px 0;
    box-shadow: none;

    .ant-select-item {
      padding: 8px 15px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        border-top: 0.2px solid var(--app-selectOptionBorder);
      }
      &.ant-select-item-option-selected,
      &:hover,
      &.ant-select-item-option-active {
        background: var(--app-selectOptionActive);
        font-weight: 300;
      }
    }
  }
}

.app-date-picker {
  &.date-picker-active {
    border-color: var(--app-primaryColor);
  }
  * {
    font-weight: 300;
  }
  input {
    max-width: 70px;
  }
}
.date-picker-dropdown .ant-picker-cell-inner {
  font-weight: 300;
}
