.app-primary-btn,
.app-default-btn {
  height: 42px;
  line-height: normal;
  padding: 12px 28px;

  .ant-btn-loading-icon {
    padding-right: 5px;
  }

  &:hover {
    box-shadow: $button-hover-box-shadow;
  }

  &:disabled {
    box-shadow: none;
  }
}

.app-link {
  color: var(--app-accentColor);
  &:hover {
    color: var(--app-primaryColor);
  }
}
