.question-form {
  box-shadow: 0px 1px 10px 1px #e8e8ea;
  .question {
    ::placeholder {
      color: #6c757d;
    }
    &.focus {
      .ant-form-item-control-input {
        border-color: var(--chat-formInputFocus);
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
      }
    }
    &.disabled {
      .ant-form-item-control-input {
        background-color: var(--chat-formInputDisabled);
      }
      .ant-input-outlined {
        background-color: var(--chat-formInputDisabled);
      }
    }
    .ant-form-item-control-input {
      background: var(--chat-formInputBg);
      padding: 10px;
      border-radius: 8px;

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        .ant-input-outlined {
          border: none;
          box-shadow: none;
          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            border: none;
            box-shadow: none;
          }
        }
      }
    }

    .query-button {
      border: none;
      border-radius: 50%;
      padding: 10px;
      .question-send-icon {
        svg {
          path {
            stroke: var(--chat-questionSendBtnStroke);
          }
        }
      }
    }
  }
}
