.custom-modal-wrapper {
  .ant-modal-content {
    padding: 24px 24px 16px 24px;
    text-align: center;
    font-weight: 400;
    color: var(--app-primaryText);
  }
  .ant-modal-footer {
    width: 100%;
    border-top: 1px solid var(--app-modalFooterBorder);
    padding-top: 17px;
  }
}
