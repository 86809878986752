$button-default-box-shadow:
  0px 4px 40px 0px rgba(66, 79, 6, 0.04),
  0px 4px 20px 0px rgba(66, 79, 6, 0.02);

$button-hover-box-shadow:
  0px 4px 15px 0px rgba(0, 0, 0, 0.25),
  0px 4px 40px 0px rgba(66, 79, 6, 0.04),
  0px 4px 20px 0px rgba(66, 79, 6, 0.02);

$input-box-shadow: 0px 8px 20px 0px rgba(199, 199, 199, 0.1);

$input-border-radius: 26px;
$button-border-radius: 57px;
