.app-sidebar {
  .ant-drawer-header {
    border: none;
  }
  .ant-drawer-close {
    order: 2;
    svg path {
      fill: var(--app-accentColor);
      stroke-width: 50;
      stroke: var(--app-accentColor);
    }
    &:hover {
      background-color: transparent;
      svg path {
        fill: var(--app-primaryColor);
        stroke: var(--app-primaryColor);
      }
    }
  }
  .ant-drawer-content-wrapper {
    position: fixed;
    height: 100%;
    border-right: 1px solid var(--app-cardBorder);
    line-height: 1.5;
    font-size: 16px;
    font-weight: lighter;
    white-space: pre-wrap;
    .ant-drawer-body {
      padding: 0 30px 30px;
    }
    .ant-drawer-header-title {
      justify-content: end;
    }
    .sidebar-url-wrapper {
      margin-bottom: 10px;

      &:hover {
        .anticon-link {
          svg path {
            fill: var(--app-primaryColor);
            stroke: var(--app-primaryColor);
          }
        }
      }
      .anticon-link {
        margin-right: 5px;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: var(--app-accentColor);
            stroke-width: 15;
            stroke: var(--app-accentColor);
          }
        }
      }
    }
  }
}
