@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import "./styles/shared";
@import "./styles/forms";
@import "./styles/buttons";

* {
  font-family: "Oxygen", serif;
}

body {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
}
