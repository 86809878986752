$header-height: 60px;
$border-radius: 3px;

.app-header {
  height: $header-height;
  background-color: var(--layout-headerBg);
  box-shadow: 0px 4px 40px 0px #c1c1c11a;
  position: relative;

  .left-section {
    width: 80%;

    .nav-menu {
      width: 50%;
      border-bottom: 0;
      display: inline-flex;
      justify-content: space-around;
      height: $header-height;

      .ant-menu-item {
        display: flex;
        align-items: center;
        padding: 0 24px;
        font-size: 15px;
        color: var(--app-primaryText);

        &::after {
          display: none;
        }

        &:hover {
          color: var(--app-hoverColor);

          .ant-menu-item-icon {
            svg path {
              fill: var(--app-hoverColor);
            }
          }
        }

        &.ant-menu-item-selected {
          font-weight: bold;

          .ant-menu-item-icon {
            svg path {
              fill: var(--app-primaryColor);
            }
          }

          &::after {
            border-bottom: 3px solid var(--app-primaryColor);
            border-radius: $border-radius;
            display: block;
          }
        }
      }
    }
  }

  .db-select {
    min-width: 185px;
  }
}

.app-content-wrapper {
  background-color: var(--layout-contentBg);
  min-height: calc(100vh - $header-height - 32px);
  height: calc(100vh - $header-height - 32px);
  padding: 16px 70px;
  overflow-y: auto;
}
