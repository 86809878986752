.listing-card {
  margin-top: 20px;

  .listing-wrapper {
    overflow-y: auto;
    max-height: 530px;

    .list-item {
      background-color: var(--app-cardItemBg);
      padding: 20px;
      margin-bottom: 12px;
      border-radius: 7px;

      .item-content {
        font-size: 16px;
        font-weight: 300;
        margin: 12px 0 0;
        .ant-typography-collapse,
        .ant-typography-expand {
          margin-left: 5px;
          font-size: 14px;
          color: var(--app-primaryColor);
          &:hover {
            color: var(--app-hoverColor);
          }
        }
      }

      .item-title {
        margin: 0;
      }
    }
  }
}
