.chat-bot-container {
  width: 55%;
  margin-left: 22%;

  .chat-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 8px 8px;
    &.initial {
      margin-bottom: 40px;
      margin-top: 15%;
      .chat-header-initial-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        font-weight: bold;
        color: var(--chat-headerTitleColor);
        white-space: nowrap;
      }
    }

    .new-chat-button {
      border: none;
      padding: 7px;
      background-color: transparent;
      color: var(--chat-newChatBtnStroke);

      .new-chat-icon {
        svg {
          path {
            stroke: var(--chat-newChatBtnStroke);
          }
        }
      }

      &:not(:disabled):hover {
        background-color: var(--chat-newChatBtnHoverBg);
        color: var(--chat-newChatBtnStroke);
      }

      &:disabled {
        background-color: transparent;
        color: var(--chat-newChatBtnDisabledStroke);

        .new-chat-icon {
          svg {
            path {
              stroke: var(--chat-newChatBtnDisabledStroke);
            }
          }
        }
      }
    }
  }
}
