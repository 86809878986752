.chat-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: var(--chat-chatPanelBg);
  border: none;
  border-radius: 8px;
  height: calc(100vh - 304px);
  .chat-bubble-container {
    .chat-bubble {
      display: flex;
      flex-direction: column;
      .chat-bubble-content {
        padding: 10px;
        display: flex;
        gap: 5px;
        align-items: start;
        .ant-typography {
          margin-bottom: 0px;
          p {
            margin-bottom: 0;
            margin-top: 3px;
            padding: 0 10px;
          }
        }
      }
      .chat-bubble-sources-btn {
        background-color: var(--chat-sourcesBtnBg);
        color: var(--app-primaryText);
        border: 1px solid var(--chat-sourcesBtnBorder);
        border-radius: 12px;
        padding: 10px 20px;
        transition: all 0.3s ease;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        width: fit-content;
        margin: 5px 55px;
        &:hover {
          background-color: var(--chat-sourcesBtnHoverBg);
          border-color: var(--chat-sourcesBtnHoverBorder);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
        }
      }
    }
    &.chat-bubble-user-message {
      align-self: flex-end;
      .chat-bubble {
        border-radius: 16px;
        width: fit-content;
        background-color: var(--chat-chatBubbleUserBg);
      }
      .chat-bubble-action-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
    &.chat-bubble-system-message {
      .chat-bubble {
        .ant-typography {
          color: black;
        }
        .chat-bubble-content {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .chat-bubble-action-wrapper {
        margin-left: 36px;
      }
    }
    .chat-bubble-action {
      .feedback-btn {
        font-weight: lighter;
        font-size: 12px;

        color: var(--app-primaryText);
        padding: 6px;

        &:disabled {
          color: var(--app-colorTextDisabled);
          svg path {
            stroke: var(--app-colorTextDisabled);
          }
        }
        &.selected {
          font-weight: normal;
        }

        &:not(:disabled) {
          &:hover {
            svg {
              stroke-width: 1.2;
            }
          }
        }

        &.feedback-btn-yes {
          margin-left: 15px;

          &.selected {
            &:not(:disabled) {
              border: 1px solid var(--app-primaryColor);
            }
          }

          &:hover:not(:disabled) {
            color: var(--app-primaryColor);
          }
        }

        &.feedback-btn-no {
          &.selected {
            &:not(:disabled) {
              border: 1px solid var(--app-dangerText);
            }
          }

          &:hover:not(:disabled) {
            color: var(--app-dangerText);
          }
        }
      }

      .copy-btn {
        opacity: 0;
        transition: all 0.3s ease;
        margin-left: 5px;
        svg path {
          stroke: var(--chat-copyBtnStroke);
        }

        &.selected {
          &:not(:disabled) {
            border: 1px solid var(--app-primaryColor);
          }
        }

        &:hover:not(:disabled) {
          svg path {
            stroke: var(--chat-copyBtnHoverStroke);
          }
        }
      }
    }

    &:hover {
      .copy-btn {
        opacity: 1;
      }
    }
  }
  .waiting-for-response-loader {
    display: flex;
    gap: 10px;
    font-size: 14px;
    align-items: center;
    background: linear-gradient(90deg, #f0f0f0, #000, #f0f0f0);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: waiting-gradient-animation 4s infinite linear;
  }
}
@keyframes waiting-gradient-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.source-list-container {
  .listing-wrapper {
    width: 300px;
    height: 300px;
    overflow-y: auto;
    .list-item {
      flex-direction: column;
      cursor: pointer;
      padding: 10px 15px;

      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      }
      .used-source {
        border-radius: 20px;
        border: 0.4px solid var(--app-primaryText);
        padding: 4px 15px;
        font-weight: 300;
      }
      &.selected-document {
        background-color: var(--app-selectedCardBg);
      }
      &.active-source {
        border: 1px solid var(--app-primaryColor);
        .item-content,
        .item-content * {
          font-weight: 600;
        }
      }
      .ant-typography {
        margin: 0;
        font-size: 12px;
        line-height: 14px;

        * {
          font-size: 12px;
          line-height: 14px;
        }
        .ant-typography-expand,
        .ant-typography-collapse {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .view-all-btn {
      font-weight: 700;
      align-self: flex-end;
      padding: 12px 20px;
      margin: 20px 0;
    }
    .ant-btn-default {
      svg path {
        stroke: var(--app-primaryText);
      }
    }
  }
}
